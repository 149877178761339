/*! CSS Used from: https://www.rxvantage.com/static/css/main.aaa97bbe.css */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
}
.container-fluid {
  padding-right: 2rem;
  padding-left: 2rem;
}
.row {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
.col-xs-12 {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}
@media only screen and (min-width: 48em) {
  .col-sm-6 {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
a {
  background-color: transparent;
}
strong {
  font-weight: bolder;
}
button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button {
  overflow: visible;
}
button {
  text-transform: none;
}
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type="submit"]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.h4,
h1,
h3 {
  line-height: 1.3;
  font-weight: 300;
}
h1 {
  font-size: 3em;
}
@media (min-width: 1100px) {
  h1 {
    font-size: 3.375em;
  }
}
h3 {
  font-size: 2em;
}
@media (min-width: 1100px) {
  h3 {
    font-size: 2.125em;
  }
}
.h4 {
  font-size: 1.175em;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .h4 {
    font-size: 1.5em;
  }
}
p {
  margin: 1em 0;
  line-height: 1.8;
}
p,
ul {
  font-size: 0.9375em;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: currentColor;
}
a:hover {
  color: #67acd6;
}
button {
  color: currentColor;
}
button:focus {
  outline: 0;
}
.container-fluidd {
  max-width: 85rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@media (min-width: 768px) {
  .container-fluid {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media (min-width: 1100px) {
  .container-fluid {
    padding-left: 80px;
    padding-right: 80px;
  }
}
.row {
  margin-left: -1.31255em;
  margin-right: -1.31255em;
}
.row [class*="col"] {
  padding-left: 1.31255em;
  padding-right: 1.31255em;
}
.text--center {
  text-align: center;
}
.text--small {
  font-size: 0.8125em;
  line-height: 1.7;
}
.icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1em;
}
.icon svg {
  width: 1em;
  height: 1em;
}
.btn {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-width: 220px;
  padding: 1em 2em;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  border-radius: 3em;
  border: 0;
  font-size: 1em;
  background-color: transparent;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/* .btnn {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-width: 220px;
  padding: 1em 2em;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  border-radius: 3em;
  border: 0;
  font-size: 1em;
  background-color: transparent;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin: 0 auto;
} */

.btn,
.btn:focus,
.btn:hover {
  color: #fff;
}

.btn--outline.btn--outline {
  -webkit-box-shadow: inset 0 0 0 1px currentColor;
  box-shadow: inset 0 0 0 1px currentColor;
}
.btn--outline.btn--outline,
.btn--outline.btn--outline:focus,
.btn--outline.btn--outline:hover {
  background-color: transparent;
}
.btn--white {
  background-color: blue;
}
.btn--white.btn--outline {
  color: blue;
}
.btn--white.btn--outline:focus,
.btn--white.btn--outline:hover {
  background-color: #fff;
  -webkit-box-shadow: inset 0 0 0 1px #fff;
  box-shadow: inset 0 0 0 1px #fff;
  color: #fff;
}
.btn--white.btn--outline.btn--white:focus,
.btn--white.btn--outline.btn--white:hover {
  color: #00448a;
  -webkit-box-shadow: inset 0 0 0 1px #fff, 0 1em 2em 0.35em rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 0 1px #fff, 0 1em 2em 0.35em rgba(0, 0, 0, 0.2);
}
.section {
  margin-top: 5em;
  margin-bottom: 5em;
}
@media (min-width: 768px) {
  .section {
    margin-top: 7em;
    margin-bottom: 7em;
  }
}
@media (min-width: 1100px) {
  .section {
    margin-top: 10em;
    margin-bottom: 10em;
  }
}
@media (min-width: 1440px) {
  .section {
    margin-top: 13em;
    margin-bottom: 13em;
  }
}
@media (min-width: 768px) {
  .section--sm {
    margin-top: 7em;
    margin-bottom: 7em;
  }
}
.section--nmt {
  margin-top: 0;
}
.checkmark-list {
  font-size: 0.875em;
  line-height: 2;
}
.checkmark-list li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}
.checkmark-list .icon {
  margin-right: 1em;
  position: relative;
  top: 0.5em;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: #67acd6;
}
.page--join {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: white;
  color: black;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.page--join a:focus {
  color: #67acd6;
}
.page--join > .container-fluid {
  width: 100%;
}
.page--join footer {
  margin-top: auto;
  font-weight: 300;
  border-top: 1px solid hsla(0, 0%, 100%, 0.2);
}
.page--join footer p {
  margin: 1.5em auto;
  font-size: 0.8125em;
}
.join__header {
  position: relative;
}
@media (min-width: 768px) {
  .join__header {
    margin-bottom: 3em;
  }
}
.join__header a {
  position: relative;
  z-index: 2;
}
@media (max-width: 767px) {
  .join__header h1 {
    margin-bottom: 0;
    font-size: 1.4em;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .join__header h1 {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.join__block {
  display: block;
  padding: 2em;
  border: 1px solid hsla(0, 0%, 0%, 0.2);
  border-radius: 1em;
}
@media (max-width: 767px) {
  .join__block {
    margin-top: 1.5em;
  }
  .join__block .checkmark-list {
    display: none;
  }
}
@media (min-width: 768px) {
  .join__block {
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .join__block {
    padding-left: 3em;
    padding-right: 3em;
  }
}
@media (min-width: 1100px) {
  .join__block {
    padding: 3em 5em;
  }
}
.join__block:focus,
.join__block:hover {
  border: 1px solid hsla(234, 100%, 50%, 0.5);
  color: currentColor;
}
.join__block:focus .btn,
.join__block:hover .btn {
  color: #00448a;
  background-color: #fff;

  -webkit-box-shadow: inset 0 0 0 1px #fff, 0 1em 2em 0.35em rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 0 1px #fff, 0 1em 2em 0.35em rgba(0, 0, 0, 0.2);
}
.join__block > .icon {
  display: inline-block;
  overflow: hidden;
  font-size: 3.125em;
}
.join__block h3 {
  margin-top: 0;
  margin-bottom: 0.5em;
}
.join__block p {
  font-weight: 300;
}
.join__block .btn {
  margin-top: 3em;
}
@media (max-width: 767px) {
  .join__block .btn {
    margin-top: 1em;
  }
}
.page {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.page > :not(.page-header):first-child {
  padding-top: 4em;
}
@media (min-width: 768px) {
  .page > :not(.page-header):first-child {
    padding-top: 6em;
  }
}
@media (min-width: 1100px) {
  .page > :not(.page-header):first-child {
    padding-top: 10em;
  }
}
.page > :not(.page-header):first-child > .section:first-child {
  margin-top: 2em;
}

.form_box{
  margin: 100px;
}
