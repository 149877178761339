@import 'zeplin-base.scss';

.TopLogo {
  display: flex;
  width: 100%;
  padding-top: 20px;
  align-items: center;
  justify-content: center;
  > img {
    max-width: 145px;
  }
  @include from ($tablet) {
    display: none;
    &.--show {
      display: flex;
    }
  }
}
