.main_header {
  height: auto !important;
}
.main-header{
  background-color:transparent;
}
nav.navbariL .link {
  position: absolute;
  font-size: 18px;
  background-color: transparent;
  padding: 0px 0px 0px 130px;
}
nav.navbariL .link:hover,
.logout_navbar:hover {
  color: blue;
}
a.navbar-brand {
  margin: 0;
  padding: 0px 0px 0px 0px;
}
/* .navbar {
  background-color: white;
} */
.navbar div.collapse {
  background-color: transparent;
}
.myreplogo{
  padding-top: 20px;
  padding-left: 20px;
}
img.logo {
  /* width: 25%; */
}
.navbaritems{
  padding-right: 20px;
}