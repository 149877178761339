/* html {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
} */

*,
*:before,
*:after {
  box-sizing: inherit;
}

.background {
  padding: 0 25px 25px;
  position: relative;
  width: 100%;
}

.background::after {
  content: "";
  background: #e6ebf1;
  /* background: -moz-linear-gradient(top, #60a9ff 0%, #4394f4 100%);
  background: -webkit-linear-gradient(top, #60a9ff 0%, #4394f4 100%);
  background: linear-gradient(to bottom, #60a9ff 0%, #4394f4 100%); */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#60a9ff', endColorstr='#4394f4',GradientType=0 );
  /* height: 100%; */
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

@media (min-width: 900px) {
  .background {
    padding: 0 0 25px;
  }
}

.containerr {
  margin: 0 auto;
  padding: 50px 0 0;
  max-width: 960px;
  width: 100%;
}

.panel {
  background-color: #fff;
  border-radius: 10px;
  /* padding: 15px 25px; */
  position: relative;
  width: 100%;
  z-index: 10;
  margin: 0 auto;
}
.card{
  border: none;
}
.pricing-table {
  /* margin-top: -150px; */
  margin-bottom: 50px;
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08),
    0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
}

@media (min-width: 900px) {
  .pricing-table {
    flex-direction: row;
  }
}

.pricing-table * {
  text-align: center;
  text-transform: uppercase;
}

.pricing-plan {
  border-bottom: 1px solid #e1f1ff;
  padding: 25px;
}

.pricing-plan:last-child {
  border-bottom: none;
}

@media (min-width: 900px) {
  .pricing-plan {
    border-bottom: none;
    border-right: 1px solid #e1f1ff;
    flex-basis: 100%;
    padding: 25px 50px;
  }

  .pricing-plan:last-child {
    border-right: none;
  }
}

.pricing-img {
  margin-bottom: 25px;
  max-width: 100%;
}

.pricing-header {
  color: #888;
  font-weight: 600;
  letter-spacing: 1px;
}

.pricing-features {
  color: #016ff9;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 50px 0 25px;
}

.pricing-features-item {
  border-top: 1px solid #e1f1ff;
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 0;
}

.pricing-features-item:last-child {
  border-bottom: 1px solid #e1f1ff;
}

.pricing-price {
  color: #016ff9;
  display: block;
  font-size: 29px;
  font-weight: 700;
}

.dollar, .nintyninecents{
  font-size: 17px;
  vertical-align:text-top;
}

.pricing-button {
  border: 1px solid #9dd1ff;
  border-radius: 10px;
  color: #348efe;
  display: inline-block;
  margin: 25px 0;
  padding: 15px 35px;
  text-decoration: none;
  transition: all 150ms ease-in-out;
}

.pricing-button:hover,
.pricing-button:focus {
  background-color: #e1f1ff;
}

.pricing-button.is-featured {
  background-color: #48aaff;
  color: #fff;
}

.pricing-button.is-featured:hover,
.pricing-button.is-featured:active {
  background-color: #269aff;
}

/* Radio Buttons */

.containur {
  /* background: #e6ebf1; */
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  margin-top: 3em;
}

/* body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  color: #333;
  background-color: #eee;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 200;
  display: inline-block;
}

h1 {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 2px solid #2fcc71;
  max-width: 40%;
  margin: 20px auto;
}

/* CONTAINERS */

.four {
  width: 45.26%;
  /* max-width: 52.26%; */
  border-radius: 2%;
  /* display: inline-block; */
  /* max-height: 20%; */
  height: 5%;
}

/* COLUMNS */

.col {
  display: block;
  float: left;
  margin: 1% 0 1% 1.6%;
}

.col:first-of-type {
  margin-left: 0;
}

/* CLEARFIX */

.cf:before,
.cf:after {
  content: " ";
  display: block;
}

/* .cf:after {
  clear: both;
} */

.cf {
  /* display: flex; */
}

.plan {
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

/* FORM */

.form .plan input,
.form .payment-plan input,
.form .payment-type input {
  display: none;
}

.form label {
  position: relative;
  color: #fff;
  background-color: #aaa;
  font-size: 26px;
  text-align: center;
  height: 150px;
  line-height: 150px;
  display: block;
  cursor: pointer;
  border: 3px solid transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.form .plan input:checked + label,
.form .payment-plan input:checked + label,
.form .payment-type input:checked + label {
  /* border: 3px solid #333; */
  background-color: blue;
}

.form .plan input:checked + label:after,
form .payment-plan input:checked + label:after,
.form .payment-type input:checked + label:after {
  content: "\2713";
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  /* border: 2px solid #333; */
  background-color: blue;
  z-index: 999;
  position: absolute;
  top: -10px;
  right: -10px;
}
.card{
  margin-bottom: 0px !important;
}
.pricing-plan{
  margin: 100px;
}
.premier_card{
  margin: auto;
 }
/* .loginspace{
  margin-top: 30px;
} */
