@import 'zeplin-base.scss';

.Card {
  background-color: #FFF;
  border: 2px solid $dark;
  border-radius: 8px;
  &.Shadow {
    border: none;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.24);
  }
  &.--selectable {
    cursor: pointer;

    &.--selected {
      border-color: $light;
      box-shadow: $standard-box-shadow;

    }
  }
}
