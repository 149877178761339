@import 'zeplin-base.scss';

.RegisterClient {
  background-color: $background;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .InputContainer {
    max-width: 100%;
  }
}
