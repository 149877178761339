/*! CSS Used from: https://my.rxvantage.com/css/new_login.css */
a {
  background-color: transparent;
}
img {
  border-style: none;
}
input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
input {
  overflow: visible;
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
h3 {
  line-height: 1.3;
  font-weight: 300;
}
ap h3 {
  font-size: 2em;
}
@media (min-width: 1100px) {
  h3 {
    font-size: 2.125em;
  }
}
p {
  margin: 1em 0;
  line-height: 1.8;
}
p,
ul {
  font-size: 0.9375em;
}
img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: currentColor;
}
a:hover {
  color: #67acd6;
}
.btn {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-width: 220px;
  padding: 1em 2em;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  border-radius: 3em;
  border: 0;
  font-size: 1em;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 0.3em;
}
.btn,
.btn:hover {
  color: #fff;
}
.btn--blue {
  background-color: #00448a;
}
.btn--blue:not(.btn--outline):hover {
  -webkit-box-shadow: 0 1em 2em 0.35em rgba(0, 0, 0, 0.2);
  box-shadow: 0 1em 2em 0.35em rgba(0, 0, 0, 0.2);
}
/*! CSS Used from: https://my.rxvantage.com/css/style.min.css ; media=all */
@media all {
  a,
  div,
  form,
  h3,
  img,
  li,
  p,
  span,
  ul {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  ul {
    list-style: none;
  }
  a {
    text-decoration: none;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  .top-dropdown .content-container input[type="password"],
  .top-dropdown .content-container input[type="text"] {
    border: 2px solid #fff;
    border-radius: 5px;
    color: #666;
    font-size: 18px;
    line-height: 40px;
    margin-bottom: 30px;
    max-width: 330px;
    padding: 0 20px;
    width: 100%;
  }
  .content-container {
    margin: 0 auto;
    max-width: 1150px;
    padding: 0 20px;
  }
  .content-container:after {
    content: " ";
    display: block;
    clear: both;
  }
  #container {
    max-width: 100%;
    overflow: hidden;
  }
  .top-dropdown {
    background: #fff none repeat scroll 0 0;
    display: none;
  }
  .top-dropdown .content-container {
    position: relative;
    padding-top: 25px;
  }
  .top-dropdown .content-container .half {
    box-sizing: border-box;
    float: left;
    padding: 25px 50px 30px;
    text-align: center;
    width: 50%;
  }
  .top-dropdown .content-container .half:last-child {
    background: transparent
      url(https://my.rxvantage.com/images/top-dropdown-bg.png) no-repeat scroll
      left top 27px;
    min-height: 333px;
    padding-left: 52px;
  }
  .top-dropdown .content-container h3 {
    background: transparent url(https://my.rxvantage.com/images/logo-small.png)
      no-repeat scroll center top;
    color: #5f656b;
    font-family: AkkuratLight;
    font-size: 30px;
    line-height: 1.2em;
    padding: 30px 0 20px;
  }
  .top-dropdown .content-container p {
    color: #868d91;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 15px;
  }
  .top-dropdown .content-container ul {
    display: inline-block;
    margin-bottom: 25px;
    margin-left: 53px;
    text-align: left;
  }
  .top-dropdown .content-container li {
    color: #5f656b;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    margin: 5px 0;
    position: relative;
  }
  .top-dropdown .content-container li:before {
    background: transparent url(https://my.rxvantage.com/images/list-check.png)
      no-repeat scroll center center;
    content: " ";
    display: inline-block;
    height: 11px;
    margin-right: 13px;
    width: 14px;
  }
  .top-dropdown .content-container a {
    font-size: 14px;
    line-height: 22px;
    color: #868d91;
    text-decoration: none;
  }
  @media only screen and (max-width: 480px) {
    .top-dropdown .content-container p,
    .top-dropdown .content-container ul {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  @media only screen and (max-width: 700px) {
    .top-dropdown .content-container .half,
    .top-dropdown .content-container .half:last-child {
      background: transparent none repeat scroll 0 0;
      float: none;
      min-height: 0;
      padding: 10px 40px;
      width: auto;
    }
    .top-dropdown .content-container .half:last-child {
      border-top: 1px solid #fff;
      padding-top: 20px;
    }
  }
  #login-dropdown {
    background-color: #fbfbfb;
  }
  #login-dropdown input {
    border: 2px solid #b4b4b4;
    color: #868d91;
    background-color: #fff;
    transition: all ease-in-out 0.15s;
    font-size: 15px;
    line-height: 15px;
    padding: 9px 15px;
    margin-bottom: 17px;
    /* width: 60%; */
  }
  #login-dropdown input:focus {
    outline: none;
    border: 2px solid #868d91;
    box-shadow: 0 0 5px #868d91;
  }
  #login-dropdown .content-container ul {
    display: block;
    text-align: center;
    margin: auto;
  }
  #login-dropdown .content-container li {
    display: block;
  }
  #login-dropdown .content-container li:before {
    background: none;
    content: none;
    display: inline-block;
  }
  #login-dropdown .content-container p {
    margin-top: 15px;
  }
  #login-dropdown .content-container .lock {
    position: relative;
    top: 2px;
    right: 2px;
  }
  @media only screen and (max-width: 480px) {
    .top-log-in .content-container {
      position: relative;
      padding-top: 25px !important;
      padding-bottom: 50px;
    }
  }
  @media only screen and (max-width: 768px) {
    .top-log-in .content-container {
      position: relative;
      padding-top: 25px;
    }
  }
}
/*! CSS Used from: Embedded */
.top-dropdown .content-container h3 {
  background: none;
  font-family: "Open Sans";
}
.top-dropdown .content-container a {
  color: white;
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
/*! CSS Used fontfaces */
@font-face {
  font-family: AkkuratLight;
  font-weight: 400;
  src: url(https://my.rxvantage.com/fonts/AkkLg_Pro_1.otf) format("opentype");
}
@font-face {
  font-family: AkkuratLight;
  font-style: italic;
  src: url(https://my.rxvantage.com/fonts/AkkLgIt_Pro_1.otf) format("opentype");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OX-hpKKSTj5PW.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OVuhpKKSTj5PW.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OXuhpKKSTj5PW.woff2)
      format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OUehpKKSTj5PW.woff2)
      format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OXehpKKSTj5PW.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OXOhpKKSTj5PW.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN_r8OUuhpKKSTjw.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWJ0bf8pkAp6a.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFUZ0bf8pkAp6a.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWZ0bf8pkAp6a.woff2)
      format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVp0bf8pkAp6a.woff2)
      format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFWp0bf8pkAp6a.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFW50bf8pkAp6a.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOX-hpKKSTj5PW.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOVuhpKKSTj5PW.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOXuhpKKSTj5PW.woff2)
      format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOUehpKKSTj5PW.woff2)
      format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOXehpKKSTj5PW.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOXOhpKKSTj5PW.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
.forgot_link{
  color: #5f656b;
}
.login-text, .login-form{
  max-width: 260px;
  margin-right: 0px;
  margin-left: 0px;
  }
  .join_free{
    margin-bottom: 50px;
  }


