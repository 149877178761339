@import "zeplin-base.scss";

.Login {
  background-color: $background;
  padding: 0px 32px;
  @include from($tablet) {
    padding: 0px;
  }
  h2 {
    font-size: 32px !important;
  }
  .LoginForm {
    max-width: 100%;
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Button {
    width: 265px;
    max-width: 85%;
  }

  .Logo {
    display: none;
    @include from($tablet) {
      display: block;
    }
  }

  .ForgotPassword {
    text-align: center;
    font-weight: normal;
    font-size: 16px;
    display: block;
    margin-top: 30px;
    @include from($tablet) {
      font-size: 12px;
      text-align: left;
      margin-top: 5px;
    }
    margin-bottom: 45px;
  }

  .TextInputContainer {
    max-width: 100%;
    width: 350px;
  }
}
