$tablet: 576px;
$laptop: 768px;

@mixin to($max) {
  @media (max-width: $max) {
    @content;
  }
}

@mixin from($min) {
  @media (min-width: $min) {
    @content;
  }
}



