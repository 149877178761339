@import "zeplin-base.scss";

.Pricing {
  max-width: 900px;
  margin: 0 auto;
  padding: 32px;
  @include to($tablet) {
    padding: 24px;
  }

  .PriceCardContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > .Card {
      width: 48%;
      min-width: 350px;
      padding: 32px;
      @include to($tablet) {
        padding: 24px;
        margin-bottom: 24px;
        width: 100%;
      }
    }
  }
  .LeadIn {
    color: $dark;
    strong {
      font-size: 24px;
    }
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    button {
      width: 166px;
    }
  }
  .FeaturesList {
    > li {
      font-weight: 600;
      font-size: 16px;
      border: none;
      margin-top: 24px;
      margin-bottom: 0px;
    }
    > p {
      margin: 0;
      font-size: 16px;
    }
  }

  .SelectableMiniCard {
    padding: 18px 24px;
    border: 2px solid rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.24);
    position: relative;
    cursor: pointer;
    &.--selected {
      color: $dark;
      &:before {
        color: white;
        position: absolute;
        content: '✓';
        background-color: $light;
        width: 32px;
        height: 32px;
        top: -16px; 
        left: -16px;
        text-align: center;
        border-radius: 100%;
        padding-top: 3px;
        font-weight: bold;
      }
      border-color: $dark;
    }
  }

  .SelectableMiniCardGroup {
    display: flex;
    > .SelectableMiniCard {
      border-radius: 4px 0 0 4px;
      &:last-child {
        border-left: none;
        border-radius: 0 4px 4px 0;
        &.--selected {
          border-left: 2px solid $dark;
        }
      }
    }
  }
}
