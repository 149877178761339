.submit_btn{
   box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
}

.header-referral{
    color: blue;
}
.header-refinvite{
    color:black;
}

.form-inline {  
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }